<template>
  <div id="empresas">
    <div class="tabela-empresas">
      <div class="col-12 novo-empresa">
        <v-btn class="primary-button" raised @click="dialog_empresa = true">
          <i class="mr-2 fas fa-plus"></i> Cadastrar empresa
        </v-btn>
      </div>
      <CustomTable
        v-if="headers != ''"
        :action="'getEmpresas'"
        :getter="$store.getters.empresas"
        :headers="headers"
        :search="true"
        :title="'Empresas'"
        :pagination="true"
        :filters="filtros"
        ref="tabela"
      >
        <template v-slot:status="{ item }">
          <span v-if="item.status">
            <img src="@/assets/images/icon-ativo.png" />
          </span>
          <span v-else>
            <img src="@/assets/images/icon-inativo.png" />
          </span>
        </template>
        <template v-slot:acoes="{ item }">
          <v-btn
            class="primary-button"
            raised
            small
            @click="editarEmpresa(item.empresa_id)"
          >
            <i class="fas fa-cog"></i>
            Editar
          </v-btn>
          <v-btn
            class="primary-button"
            raised
            small
            @click="editarFinanceiro(item.empresa_id)"
          >
            <i class="fas fa-hand-holding-usd"></i>
            Dados Financeiros
          </v-btn>
        </template>
      </CustomTable>
      <v-dialog v-model="dialog_empresa" persistent max-width="600px">
        <v-card id="modal-nova-empresa">
          <v-card-title v-if="empresa.empresa_id">
            <span class="headline">Editar empresa</span>
            <v-btn class="exit-button" small @click="closeempresa">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
            <p>*Campos obrigatórios</p>
          </v-card-title>
          <v-card-title v-else>
            <span class="headline">Nova empresa</span>
            <v-btn class="exit-button" small @click="closeempresa">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
            <p>*Campos obrigatórios</p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form_empresa">
                <div class="row">
                  <div class="col-12">
                    <label class="primary-text-field-label">Nome*</label>
                    <v-text-field
                      class="primary-text-field"
                      :rules="[(v) => !!v || 'Campo Nome obrigatório']"
                      v-model="empresa.nome"
                      label="Nome"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label"
                      >Diretório logo</label
                    >
                    <v-file-input
                      class="primary-file-input"
                      v-model="empresa.diretorio_logo"
                      label="Diretório logo"
                      accept="image/*"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">CNPJ*</label>
                    <v-text-field
                      class="primary-text-field"
                      v-mask="'##.###.###/####-##'"
                      type="text"
                      :rules="[
                        (v) => validarCNPJ(v) || 'Campo CNPJ obrigatório',
                      ]"
                      v-model="empresa.cnpj"
                      label="CNPJ"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Telefone*</label>
                    <v-text-field
                      class="primary-text-field"
                      v-mask="'(##)#########'"
                      type="text"
                      :rules="[(v) => !!v || 'Campo Telefone obrigatório']"
                      v-model="empresa.telefone"
                      label="Telefone"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label"
                      >Chave financeiro*</label
                    >
                    <v-text-field
                      class="primary-text-field"
                      type="text"
                      :rules="[
                        (v) => !!v || 'Campo Chave financeiro obrigatório',
                      ]"
                      v-model="empresa.chave_financeiro"
                      label="Chave financeiro"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label"
                      >Token OkWhats</label
                    >
                    <v-text-field
                      class="primary-text-field"
                      type="text"
                      v-model="empresa.token_okwhats"
                      label="Token OkWhats"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12" v-if="empresa.token_okwhats != ''">
                    <label class="primary-text-field-label"
                      >Telefone de notificação ID*</label
                    >
                    <v-text-field
                      class="primary-text-field"
                      type="number"
                      :rules="
                        empresa.token_okwhats != ''
                          ? [
                              (v) =>
                                !!v ||
                                'Campo Telefone notificação ID obrigatório',
                            ]
                          : ''
                      "
                      v-model="empresa.telefone_id"
                      label="Telefone notificação ID"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">CEP*</label>
                    <v-text-field
                      class="primary-text-field"
                      v-mask="'#####-###'"
                      type="text"
                      :rules="[(v) => !!v || 'Campo CEP obrigatório']"
                      v-model="empresa.cep"
                      label="CEP"
                      hide-details
                      solo
                      @change="buscaCep(empresa.cep)"
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Logradouro*</label>
                    <v-text-field
                      class="primary-text-field"
                      type="text"
                      :rules="[(v) => !!v || 'Campo Logradouro obrigatório']"
                      v-model="empresa.logradouro"
                      label="Logradouro"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Número*</label>
                    <v-text-field
                      class="primary-text-field"
                      type="text"
                      :rules="[(v) => !!v || 'Campo Número obrigatório']"
                      v-model="empresa.numero"
                      label="Número"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Bairro*</label>
                    <v-text-field
                      class="primary-text-field"
                      type="text"
                      :rules="[(v) => !!v || 'Campo Bairro obrigatório']"
                      v-model="empresa.bairro"
                      label="Bairro"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Estado*</label>
                    <v-select
                      class="primary-select"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      :items="estados"
                      item-text="nome"
                      item-value="codigo"
                      v-model="empresa.uf_id"
                      label="Estado"
                      solo
                      hide-details
                      @change="buscaCidades()"
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Cidade*</label>
                    <v-select
                      class="primary-select"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      :items="cidades"
                      item-text="nome"
                      item-value="codigo"
                      v-model="empresa.cidade_id"
                      label="Cidade"
                      solo
                      hide-details
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Forma de Cálculo da Pizza*</label>
                    <v-select
                      class="primary-select"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      :items="formas_calculos"
                      item-text="nome"
                      item-value="empresa_forma_calculo_id"
                      v-model="empresa.empresa_forma_calculo_id"
                      label="Forma de Cálculo da Pizza"
                      solo
                      hide-details
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">E-mail*</label>
                    <v-text-field
                      class="primary-text-field"
                      type="text"
                      :rules="[(v) => !!v || 'Campo E-mail obrigatório']"
                      v-model="empresa.email_resposta"
                      label="E-mail"
                      hide-details
                      solo
                    />
                  </div>
                  <div class="col-12">
                    <label class="primary-text-field-label">Status*</label>
                    <v-select
                      class="primary-select"
                      :rules="[(v) => !!v || 'Campo Status obrigatório']"
                      :items="['Ativo', 'Inativo']"
                      v-model="empresa.status"
                      label="Status"
                      hide-details
                      solo
                    />
                  </div>
                  <!-- <div class="col-12">
                                        <h3>Horários de Atendimento</h3>
                                        <div class="horario" v-for="(horario, i) in empresa.horarios" :key="i">
                                            <div class="inputs">
                                                <div class="row">
                                                    <div class="col-md-4 col-12">
                                                        <label class="primary-text-field-label">Nome*</label>
														<v-select
															class="primary-select" 
                                                            :rules="[v => !!v || 'Campo Dia obrigatório']" 
                                                            :items="dias_semana" 
                                                            v-model="empresa.horarios[i].dia_semana" 
                                                            item-text="texto" 
                                                            item-value="dia"
                                                            label="Dia"
                                                            hide-details
															solo
                                                        />
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        <v-dialog
                                                            ref="modal_time_de"
                                                            v-model="modal_time_de"
                                                            persistent
                                                            width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <label class="primary-text-field-label">Nome*</label>
																<v-text-field
																	class="primary-text-field"
                                                                    :rules="[v => !!v || 'Campo De obrigatório']" 
                                                                    v-model="empresa.horarios[i].de"
                                                                    label="De"
                                                                    hide-details
																	solo
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                />
                                                            </template>
                                                            <v-time-picker 
                                                                v-model="timeDe"
                                                                v-if="modal_time_de" 
                                                                full-width
                                                                format="24hr"
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-btn text color="primary" @click="modal_time_de = false">
                                                                    Cancelar
                                                                </v-btn>
                                                                <v-btn color="primary" @click="saveDe(i, timeDe)">
                                                                    OK
                                                                </v-btn>
                                                            </v-time-picker>
                                                        </v-dialog>
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        <v-dialog
                                                            ref="modal_time_de"
                                                            v-model="modal_time_ate"
                                                            persistent
                                                            width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <label class="primary-text-field-label">Nome*</label>
																<v-text-field
																	class="primary-text-field"
                                                                    :rules="[v => !!v || 'Campo Até obrigatório']" 
                                                                    v-model="empresa.horarios[i].ate"
                                                                    label="Até"
                                                                    hide-details
																	solo
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                />
                                                            </template>
                                                            <v-time-picker 
                                                                v-model="timeAte"
                                                                v-if="modal_time_ate" 
                                                                full-width
                                                                format="24hr"
                                                            >
                                                                <v-spacer></v-spacer>
                                                                <v-btn text color="primary" @click="modal_time_ate = false">
                                                                    Cancelar
                                                                </v-btn>
                                                                <v-btn color="primary" @click="saveAte(i, timeAte)">
                                                                    OK
                                                                </v-btn>
                                                            </v-time-picker>
                                                        </v-dialog>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-btn v-if="empresa.horarios.length > 1" type="button" @click="removeHorario(i)" color="primary" raised class="remover-horario">
                                                <i class="fas fa-minus"></i>
                                            </v-btn>
                                        </div>
                                        <v-btn color="primary" raised @click="addHorario">
                                            <i class="fas fa-plus"></i> Adicionar Horário
                                        </v-btn>
                                    </div> -->
                </div>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="primary-button"
              medium
              @click="modalFretes = true"
              v-if="empresa.empresa_id != ''"
            >
              Fretes
            </v-btn>
            <v-btn class="primary-button" medium @click="enviarempresa">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modalFretes" max-width="600px">
        <v-card id="modal-frete">
          <div class="titulo-modal">
            FRETES
            <v-btn
              class="exit-button"
              small
              @click="modalFretes = !modalFretes"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </div>
          <v-card v-if="empresa.fretes.length != 0">
            <v-list>
              <v-list-item
                v-for="(frete, index) in empresa.fretes"
                :key="frete.empresas_frete_id"
              >
                <v-list-item-content @click="editarFrete(index)">
                  <v-list-item-title
                    v-text="'De: ' + frete.de_km + '- Até: ' + frete.ate_km"
                  >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="editarFrete(frete.empresas_frete_id)">
                    <v-icon color="grey lighten-1">fas fa-edit</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="index > 0"
                    icon
                    @click="removerFrete(frete.empresas_frete_id)"
                  >
                    <v-icon style="width: 16px" color="red"
                      >fas fa-trash-alt</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
          <span v-if="empresa.fretes.length == 0" style="padding: 15px 0"
            >Nenhum frete cadastrado.</span
          >
          <v-card-actions>
            <v-btn
              class="primary-button"
              @click="
                modal_adicionar_frete = true;
                limparCamposFrete();
              "
            >
              ADICIONAR FRETE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modal_adicionar_frete" max-width="600px">
        <v-card id="modal-adicionar-frete">
          <v-card-title>
            ADICIONAR NOVO FRETE
            <v-btn
              class="exit-button"
              small
              @click="modal_adicionar_frete = !modal_adicionar_frete"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
            <p>*Campos obrigatórios</p>
          </v-card-title>
          <v-card-text>
            <v-form ref="form_novo_frete" class="row">
              <div class="col-md-6 col-12">
                <label class="primary-text-field-label">De:*</label>
                <v-text-field
                  class="primary-text-field"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="novoFrete.fretes[0].de_km"
                  label="De:"
                  type="number"
                  suffix="km"
                  solo
                  hide-details
                />
              </div>
              <div class="col-md-6 col-12">
                <label class="primary-text-field-label">Até:*</label>
                <v-text-field
                  class="primary-text-field"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="novoFrete.fretes[0].ate_km"
                  label="Até:"
                  type="number"
                  suffix="km"
                  solo
                  hide-details
                />
              </div>
              <div class="col-12">
                <label class="primary-text-field-label">Estado*</label>
                <v-select
                  class="primary-select"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  :items="estados"
                  item-text="nome"
                  item-value="codigo"
                  v-model="novoFrete.fretes[0].uf_id"
                  label="Estado"
                  solo
                  hide-details
                  @change="buscaCidades()"
                />
              </div>
              <div class="col-12">
                <label class="primary-text-field-label">Cidade*</label>
                <v-select
                  class="primary-select"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  :items="cidades"
                  item-text="nome"
                  item-value="codigo"
                  v-model="novoFrete.fretes[0].cidade_id"
                  label="Cidade"
                  solo
                  hide-details
                />
              </div>
              <div class="col-12">
                <label class="primary-text-field-label">Bairro*</label>
                <v-text-field
                  class="primary-text-field"
                  type="text"
                  :rules="[(v) => !!v || 'Campo Bairro obrigatório']"
                  v-model="novoFrete.fretes[0].bairro"
                  label="Bairro"
                  hide-details
                  solo
                />
              </div>
              <div class="col-12">
                <label class="primary-text-field-label">Valor do frete*</label>
                <v-text-field
                  class="primary-text-field"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="novoFrete.fretes[0].valor_frete"
                  label="Valor do frete"
                  type="number"
                  solo
                  hide-details
                />
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="wrapper-btns">
              <v-btn
                class="primary-button"
                medium
                @click="adicionarFrete(empresa.empresa_id)"
              >
                Salvar
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modal_editar_frete" max-width="600px">
        <v-card id="modal-editar-frete">
          <v-card-title>
            Editar frete
            <v-btn
              class="exit-button"
              small
              @click="modal_editar_frete = !modal_editar_frete"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
            <p>*Campos obrigatórios</p>
          </v-card-title>
          <v-card-text>
            <v-form ref="form_novo_frete" class="row">
              <div class="col-12">
                <label class="primary-text-field-label">De:*</label>
                <v-text-field
                  class="primary-text-field"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="novoFrete.fretes[0].de_km"
                  label="De:"
                  type="number"
                  suffix="km"
                  solo
                  hide-details
                />
              </div>
              <div class="col-12">
                <label class="primary-text-field-label">Até:*</label>
                <v-text-field
                  class="primary-text-field"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="novoFrete.fretes[0].ate_km"
                  label="Até:"
                  type="number"
                  suffix="km"
                  solo
                  hide-details
                />
              </div>
              <div class="col-12">
                <label class="primary-text-field-label">Bairro*</label>
                <v-text-field
                  class="primary-text-field"
                  type="text"
                  :rules="[(v) => !!v || 'Campo Bairro obrigatório']"
                  v-model="novoFrete.fretes[0].bairro"
                  label="Bairro"
                  hide-details
                  solo
                />
              </div>
              <div class="col-12">
                <label class="primary-text-field-label">Valor do frete*</label>
                <v-text-field
                  class="primary-text-field"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="novoFrete.fretes[0].valor_frete"
                  label="Valor do frete"
                  type="number"
                  solo
                  hide-details
                />
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <div class="wrapper-btns">
              <v-btn
                class="primary-button"
                medium
                @click="salvarfreteEditado()"
              >
                Salvar
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Modal para os dados financeiros da empresa-->
      <v-dialog v-model="modal_financeiro" persistent max-width="1000px">
            <v-card>
                <v-card-title>
                    <span class="headline" v-if="empresa_financeiro.servico_id != ''">Editar dados do Financeiro</span>
                    <span class="headline" v-else>Cadastrar dados Financeiros da Empresa</span>
                    <v-btn class="exit-button" small @click="closeDadosFinanceiros">
                        <v-icon>fas fa-times</v-icon>
                    </v-btn>
                        <p>*Campos obrigatórios</p>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="form_empresa_servico">
                            <div class="row">
                                <div class="col-12">
                                    <label class="primary-text-field-label">Nome da sua Empresa*</label>
                                    <v-text-field
                                        class="primary-text-field"
                                        :rules="[v => !!v || 'Campo Nome da Empresa obrigatório']"
                                        v-model="empresa_financeiro.nome"
                                        label="Nome da Empresa"
                                        solo
                                        hide-details
                                    />
                                </div>
                                <div class="col-12">
                                    <label class="primary-text-field-label">CNPJ da sua Empresa*</label>
                                    <v-text-field
                                        class="primary-text-field"
                                        v-mask="'##.###.###/####-##'"
                                        type="text"
                                        :rules="[(v) => validarCNPJ(v) || 'Campo CNPJ obrigatório']"
                                        v-model="empresa_financeiro.cnpj"
                                        label="CNPJ da Empresa"
                                        solo
                                        hide-details
                                    />
                                </div>
                            </div>
                        </v-form>
                        <!--Tabela que ira conter a listagem dos recebedores associados-->
                        <v-data-table
                            :headers="header_recebedores"
                            :items="empresa_financeiro.recebedores"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title>
                                        Recebedores
                                    </v-toolbar-title>
                                    <v-divider
                                        class="mx-4"
                                        inset
                                        vertical
                                    />
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                            </template>
                            <!--Formatação dos campos-->
                            <template v-slot:[`item.porcentagem_recebimento`]="{ item }">
                                {{ item.porcentagem_recebimento}}%
                            </template>
                            <!--Botões para edição e exclusão de um recebedor-->
                            <template v-slot:[`item.editar`]="{ item }">
                                <v-btn class="primary-button" raised small @click="editarRecebedor(item)">
                                    <i class="fas fa-cog"></i>
                                </v-btn>
                            </template>
                            <template v-slot:[`item.deletar`]="{ item }">
                                <v-btn class="primary-button" raised small @click="removerRecebedor(item)">
                                  <i class="fas fa-trash"></i>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card-text>
                <!--Botões da nossa modal-->
                <v-card-actions>
                    <div class="wrapper-btns">
                        <v-btn class="primary-button" medium @click="cadastrarRecebedor">
                            Cadastrar Recebedor
                        </v-btn>
                        <v-btn class="primary-button" medium @click="enviarFinanceiro" v-if="empresa_financeiro.id">
                            Salvar
                        </v-btn>
                        <v-btn class="primary-button" medium @click="enviarFinanceiro" v-else>
                            Cadastrar
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
      </v-dialog>
      <!--Modal para cadastrar e editar os recebedores associado a empresa-->
      <v-dialog v-model="modal_recebedores" max-width="700px">
          <v-card>
              <v-card-title>
                  <span class="headline" v-if="empresa_financeiro.nome_conta_recebedor != ''">Editar dados do Recebedor</span>
                  <span class="headline" v-else> Cadastrar Recebedor</span>
                  <v-btn class="exit-button" small @click="closeDadosRecebedor">
                      <v-icon>fas fa-times</v-icon>
                  </v-btn>
                      <p>*Campos obrigatórios</p>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-form ref="form_empresa_recebedor">
                          <div class="row">
                              <div class="col-6">
                                  <label class="primary-text-field-label">Nome Recebedor*</label>
                                  <v-text-field
                                      class="primary-text-field"
                                      :rules="[v => !!v || 'Campo Nome Recebedor obrigatório']"
                                      v-model="recebedor_empresa.nome_conta_recebedor"
                                      label="Nome do Recebedor"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-6">
                                  <label class="primary-text-field-label">Banco*</label>
                                  <v-autocomplete
                                      class="primary-select"
                                      :rules="[(v) => !!v || 'Campo  Banco obrigatório']"
                                      :items="bancos"
                                      item-text="nome"
                                      item-value="banco_id"
                                      v-model="recebedor_empresa.banco_id"
                                      label="Banco do Recebedor"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-6">
                                  <label class="primary-text-field-label">Documento do Recebedor*</label>
                                  <v-text-field
                                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                                      class="primary-text-field"
                                      :rules="[v => !!v || 'Campo Documento Recebedor obrigatório']"
                                      v-model="recebedor_empresa.documento_recebedor"
                                      label="CPF ou CNPJ"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-6">
                                  <label class="primary-text-field-label">Porcentagem do Recebedor*</label>
                                  <v-text-field
                                      class="primary-text-field"
                                      :rules="[v => !!v || 'Campo Porcentagem Recebedor obrigatório']"
                                      v-model="recebedor_empresa.porcentagem_recebimento"
                                      label="Porcentagem do Recebedor"
                                      suffix="%"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-10">
                                  <label class="primary-text-field-label">Número da agência*</label>
                                  <v-text-field
                                      v-mask="'####'"
                                      class="primary-text-field"
                                      :rules="[v => !!v || 'Campo Número da Agência obrigatório', v => v.length == 4 || 'Campo Número da Agência Incompleto']"
                                      v-model="recebedor_empresa.agencia_recebedor"
                                      label="Nº Agência do Recebedor"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-2">
                                  <label class="primary-text-field-label">DV</label>
                                  <v-text-field
                                      v-mask="'#'"
                                      class="primary-text-field"
                                      v-model="recebedor_empresa.agencia_dv_recebedor"
                                      label="DV"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-10">
                                  <label class="primary-text-field-label">Número da conta*</label>
                                  <v-text-field
                                      v-mask="'#######'"
                                      class="primary-text-field"
                                      :rules="[v => !!v || 'Campo Número da Conta obrigatório']"
                                      v-model="recebedor_empresa.conta_recebedor"
                                      label="Nº Conta do Recebedor"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-2">
                                  <label class="primary-text-field-label">DV*</label>
                                  <v-text-field
                                      v-mask="'#'"
                                      class="primary-text-field"
                                      :rules="[v => !!v || 'Campo DV Conta obrigatório']"
                                      v-model="recebedor_empresa.conta_dv_recebedor"
                                      label="DV"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-6">
                                  <label class="primary-text-field-label">Tipo de Conta*</label>
                                  <v-select
                                      class="primary-select"
                                      :rules="[(v) => !!v || 'Campo Tipo Conta obrigatório']"
                                      :items="['Conta Corrente', 'Conta Poupança']"
                                      v-model="recebedor_empresa.tipo_conta_recebedor"
                                      label="Tipo de Conta do Recebedor"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-6">
                                  <label class="primary-text-field-label">Intervalo Transferência*</label>
                                  <v-select
                                      class="primary-select"
                                      :rules="[(v) => !!v || 'Campo Intervalo Transferência obrigatório']"
                                      :items="['Diariamente', 'Semanalmente', 'Mensalmente']"
                                      v-model="recebedor_empresa.transferencia_intervalo_recebedor"
                                      label="Intervalo para tranferir"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div v-if="recebedor_empresa.transferencia_intervalo_recebedor == 'Mensalmente'" class="col-6">
                                  <label class="primary-text-field-label">Dia Transferência*</label>
                                  <v-text-field
                                      class="primary-text-field"
                                      :rules="[v => !!v || 'Campo dia obrigatório']"
                                      v-model="recebedor_empresa.transferencia_dia_recebedor"
                                      label="Dia para Transferir"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-6">
                                  <label class="primary-text-field-label">Email</label>
                                  <v-text-field
                                      class="primary-text-field"
                                      v-model="recebedor_empresa.email_recebedor"
                                      label="Email Recebedor (opcional)"
                                      solo
                                      hide-details
                                  />
                              </div>
                              <div class="col-6">
                                  <label class="primary-text-field-label">Telefone</label>
                                  <v-text-field
                                      v-mask="['+55 (##) ####-####', '+55 (##) # ####-####']"
                                      class="primary-text-field"
                                      v-model="recebedor_empresa.telefone_recebedor"
                                      label="Telefone Recebedor (opcional)"
                                      solo
                                      hide-details
                                  />
                              </div>
                          </div>
                      </v-form>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-btn class="primary-button" medium @click="salvarRecebedor">
                      Salvar
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <DialogMensagem
        :visible="dialog_resposta"
        :mensagem="resposta"
        @close="dialog_resposta = false"
      />
      <Loader v-if="loading" />
    </div>
  </div>
</template>

<script>
// importa o store
import store from "@/store";
// importa o componente de DialogMensagem
import DialogMensagem from "@/components/DialogMensagem.vue";
// importa o componente de Loader
import Loader from "@/components/Loader.vue";
// importa o componente de CustomTable
import CustomTable from "@/components/CustomTable.vue";

// exporta o componente
export default {
  // nome do componente
  name: "empresas",
  // componentes filhos1
  components: {
    // componente de DialogMensagem
    DialogMensagem,
    // componente de CustomTable
    CustomTable,
    // componente de Loader
    Loader,
  },
  // dados do componente
  data: () => ({
    // variável para mostrar a modal para editar/criar um empresa
    dialog_empresa: false,
    // variável para mostrar a modal para configuracoes de menu da empresa
    dialog_resposta: false,
    // variável para mostrar a modal de timer picker de
    modal_time_de: false,
    // variável para o horario de da modal de time picker
    timeDe: null,
    // variável para mostrar a modal de timer picker até
    modal_time_ate: false,
    // variável para o horario até da modal de time picker
    timeAte: null,
    // Modal para abrir modal de fretes
    modalFretes: false,
    // variável para mostrar a modal de adicionar frete
    modal_adicionar_frete: false,
    // variável para mostrar a modal de edição de frete
    modal_editar_frete: false,
    // variável para mostrar a modal de bancos
    modal_financeiro: false,
    // variável para mostrar a modal de recebedores
    modal_recebedores: false,
    // variável para a mensagem de resposta
    resposta: {},
    // variável para o loading
    loading: false,
    // variável para os bairros
    bairros: [],
    // variável para os estados
    estados: [],
    // variável para as cidades
    cidades: [],
    // variável para os dias da semana
    dias_semana: [
      {
        dia: 0,
        texto: "Domingo",
      },
      {
        dia: 1,
        texto: "Segunda-feira",
      },
      {
        dia: 2,
        texto: "Terça-feira",
      },
      {
        dia: 3,
        texto: "Quarta-feira",
      },
      {
        dia: 4,
        texto: "Quinta-feira",
      },
      {
        dia: 5,
        texto: "Sexta-feira",
      },
      {
        dia: 6,
        texto: "Sábado",
      },
    ],
    contador_frete: 0,
    // variável para criar/editar empresa
    empresa: {
      empresa_id: "",
      diretorio_logo: {},
      nome: "",
      cnpj: "",
      telefone: "",
      chave_financeiro: "",
      cep: "",
      logradouro: "",
      numero: "",
      bairro: "",
      complemento: "",
      ponto_referencia: "",
      uf_id: "",
      cidade_id: "",
      fretes: [],
      token_okwhats: "",
      telefone_id: "",
      status: "",
			empresa_forma_calculo_id: "",
      email_resposta: "",
      horarios: [
        {
          dia_semana: "",
          de: "",
          ate: "",
          status: true,
        },
      ],
    },
    // variável para os dados da tabela presente na modal
    edited_index: -1,
    // variável para armazenar os dados financeiros da empresa
    empresa_financeiro: {
        id: '',
        servico_id: '',
        nome: '',
        cnpj: '',
        recebedores: [],
    },
    // variável para armazenar os bancos disponíveis
    bancos: [],
    // variável para armazenar os dados do recebedor da empresa
    recebedor_empresa: {
        email_recebedor: '',
        telefone_recebedor: '',
        documento_recebedor: '',
        porcentagem_recebimento: '',
        banco_id: '',
        banco_nome: '',
        transferencia_intervalo_recebedor: '',
        transferencia_dia_recebedor: '',
        nome_conta_recebedor: '',
        tipo_conta_recebedor: '',
        conta_recebedor: '',
        conta_dv_recebedor: '',
        agencia_recebedor: '',
        agencia_dv_recebedor: '',
    },
    // variável auxiliar para os recebedores
    recebedor_default: {
        email_recebedor: '',
        telefone_recebedor: '',
        documento_recebedor: '',
        porcentagem_recebimento: '',
        banco_id: '',
        banco_nome: '',
        transferencia_intervalo_recebedor: '',
        transferencia_dia_recebedor: '',
        nome_conta_recebedor: '',
        tipo_conta_recebedor: '',
        conta_recebedor: '',
        conta_dv_recebedor: '',
        agencia_recebedor: '',
        agencia_dv_recebedor: '',
    },
    // variável para a soma das porcentagens que cada recebedor ira receber
    soma: 0,
    novoFrete: {
      fretes: [
        {
          valor_frete: "",
          de_km: "",
          ate_km: "",
          bairro: "",
        },
      ],
    },
		formas_calculos: [],
    // variável para o cabeçalho da tabela
    headers: [
      {
        value: "empresa_id",
        text: "ID",
        sortable: true,
      },
      {
        value: "nome",
        text: "Nome",
        sortable: true,
      },
      {
        value: "cnpj",
        text: "CNPJ",
        sortable: true,
      },
      {
        value: "token",
        text: "Token",
        sortable: false,
      },
      {
        value: "telefone",
        text: "Telefone",
        sortable: false,
      },
      {
        value: "status",
        text: "Status",
        sortable: true,
      },
      {
        value: "cidade.nome",
        text: "Cidade",
        sortable: true,
      },
      {
        value: "bairro",
        text: "Bairro",
        sortable: true,
      },
      {
        value: "acoes",
        sortable: false,
        text: "Ações",
      },
    ],
    // variável para o cabeçalho da tabela de recebedores
    header_recebedores: [
      {
        value: 'nome_conta_recebedor',
        text: 'Nome',
        sortable: false,
      },
      {
        value: 'banco_nome',
        text: 'Banco',
        sortable: false,
      },
      {
        value: 'porcentagem_recebimento',
        text: 'Porcentagem Recebimento',
        sortable: false,
      },
      {
        value: 'deletar',
        text: 'Deletar',
        sortable: false,
      },
      {
        value: 'editar',
        text: 'Editar',
        sortable: false,
      },
    ],
    // variável para os filtros da tabela
    filtros: {
      perPage: 10,
    },
  }),
  // funções deste componente
  methods: {
    async init() {
      await this.buscaEstados();
      await this.buscaFormasCalculos();
    },
    addHorario() {
      this.empresa.horarios.push({
        dia: "",
        de: "",
        ate: "",
        status: true,
      });
    },
    removeHorario(index) {
      if (index > -1) {
        this.empresa.horarios.splice(index, 1);
      }
    },
    async saveDe(i, time) {
      this.empresa.horarios[i].de = await time;
      this.modal_time_de = false;
    },
    async saveAte(i, time) {
      this.empresa.horarios[i].ate = await time;
      this.modal_time_ate = false;
    },
    // função para enviar um empresa
    async enviarempresa() {
      // caso os campos do formulário estejam válidos
      if (this.$refs.form_empresa.validate()) {
        // coloca o componente como loading
        this.loading = await true;
        // coloeta os dados da empresa
        let dados = await {
          // horarios: await this.empresa.horarios,
          codigo: await this.empresa.empresa_id,
          nome: await this.empresa.nome,
          diretorio_logo: await this.empresa.diretorio_logo.name,
          cnpj: await this.empresa.cnpj.replace(/\D/g, ""),
          telefone: await this.empresa.telefone,
          chave_financeiro: await this.empresa.chave_financeiro,
          cep: await this.empresa.cep.replace(/\D/g, ""),
          logradouro: await this.empresa.logradouro,
          numero: await this.empresa.numero,
          bairro: await this.empresa.bairro,
          complemento: await this.empresa.complemento,
          ponto_referencia: await this.empresa.ponto_referencia,
          cidade_id: await this.empresa.cidade_id,
          token_okwhats: await this.empresa.token_okwhats,
          telefone_id: await this.empresa.telefone_id,
          empresa_forma_calculo_id: await this.empresa.empresa_forma_calculo_id,
          email_resposta: await this.empresa.email_resposta,
          status: (await this.empresa.status) == "Ativo" ? true : false,
        };
        // caso exista um empresa_id
        if (this.empresa.empresa_id) {
          // coleta o status da empresa
          dados.status = (await this.empresa.status) == "Ativo" ? true : false;
          // coleta o id da empresa
          dados.codigo = await this.empresa.empresa_id;
        }
        // faz a requisição para o back para coletar os grupos
        var resp = await store.dispatch("postEmpresas", dados);
        // caso o status da resposta seja 200 (deu certo)
        if (resp.status != 200) {
          this.$swal({
            title: "Atenção",
            icon: "warning",
            text: "Algo deu errado!",
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: true,
          });
          // caso tenha dado algum erro
        } else {
          this.$swal({
            icon: "success",
            text:
              "Empresa " +
              (this.empresa.empresa_id ? "editada" : "cadastrada") +
              " com sucesso!",
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: true,
          });
          this.closeempresa();
        }
        // retira o loading do componente
        this.loading = false;
        // atualiza a tabela
        this.$refs.tabela.init();
      } else {
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "Preencha todos os campos obrigatórios!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      }
    },
    // função para coletar um empresa para editar
    async editarEmpresa(empresa_id) {
      // coloca o componente como loading
      this.loading = true;
      // faz um dispatch para uma action no vuex store para pegar um empresa passando o id
      var resp = await store.dispatch("getEmpresa", empresa_id);
      // caso o status da resposta seja 200 (deu certo)
      if (resp.status == 200) {
        // atribui os dados da empresa vindos do back à váriável local
        this.empresa.empresa_id = (await resp.data.dados[0].empresa_id) || "";
        this.empresa.nome = (await resp.data.dados[0].nome) || "";
        this.empresa.diretorio_logo = resp.data.dados[0].diretorio_logo != null 
        ? {name: resp.data.dados[0].diretorio_logo, type: "image/jpeg"} 
        : {}
        this.empresa.cnpj = (await resp.data.dados[0].cnpj) || "";
        this.empresa.telefone = (await resp.data.dados[0].telefone) || "";
        this.empresa.status = (await resp.data.dados[0].status)
          ? "Ativo"
          : "Inativo";
        this.empresa.chave_financeiro =
          (await resp.data.dados[0].chave_financeiro) || "";
        this.empresa.cep = (await resp.data.dados[0].cep) || "";
        this.empresa.logradouro = (await resp.data.dados[0].logradouro) || "";
        this.empresa.numero = (await resp.data.dados[0].numero) || "";
        this.empresa.bairro = (await resp.data.dados[0].bairro) || "";
        this.empresa.complemento = (await resp.data.dados[0].complemento) || "";
        this.empresa.ponto_referencia =
          (await resp.data.dados[0].ponto_referencia) || "";
        this.empresa.uf_id = (await resp.data.dados[0].uf_id) || "";
        await this.buscaCidades();
        this.empresa.cidade_id = (await resp.data.dados[0].cidade_id) || "";
        this.empresa.token_okwhats =
          (await resp.data.dados[0].token_okwhats) || "";
        this.empresa.telefone_id = (await resp.data.dados[0].telefone_id) || "";
        this.empresa.empresa_forma_calculo_id = (await resp.data.dados[0].empresa_forma_calculo_id) || "";
        this.empresa.email_resposta = (await resp.data.dados[0].email_resposta) || "";
        // this.empresa.horarios = await resp.data.horario_atendimentos ? resp.data.horario_atendimentos : [{
        // 	dia_semana: '',
        // 	de: '',
        // 	ate: '',
        // 	status: true,
        // }]
        // mostra a modal de creat/edit empresa
        this.dialog_empresa = true;
      } else {
        // atribui o título da mensagem
        this.resposta.titulo = await "Algo deu errado!";
        // atribui o corpo da mensagem
        // this.resposta.mensagem = await  resp.data.message || resp.data.error[0].message
        // mostra a mensagem
        this.dialog_resposta = true;
      }
      // retira o loading do componente
      this.loading = false;
      // atualiza a tabela
      this.$refs.tabela.init();
    },
    // função que roda quando é fechada a modal de create/edit empresa
    closeempresa() {
      // fecha a modal
      this.dialog_empresa = false;
      // limpa os dados locais da empresa
      this.empresa = {
        empresa_id: "",
        nome: "",
        diretorio_logo: {},
        cnpj: "",
        telefone: "",
        chave_financeiro: "",
        cep: "",
        logradouro: "",
        numero: "",
        bairro: "",
        complemento: "",
        ponto_referencia: "",
        uf_id: "",
        cidade_id: "",
        fretes: "",
        token_okwhats: "",
        telefone_id: "",
        status: "",
				empresa_forma_calculo_id: "",
				email_resposta: "",
        horarios: [
          {
            dia_semana: "",
            de: "",
            ate: "",
            status: true,
          },
        ],
      };
    },
    validarCNPJ(value) {
      var cnpj = value.replace(/[^\d]+/g, "");
      if (cnpj == "") return false;
      if (cnpj.length != 14) return false;
      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      ) {
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "CNPJ incorreto!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      }
      // Valida DVs
      var tamanho = cnpj.length - 2;
      var numeros = cnpj.substring(0, tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) {
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "CNPJ incorreto!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
        return false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) {
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "CNPJ incorreto!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
        return false;
      }

      return true;
    },
    async buscaCep(cep_busca) {
      if (cep_busca.length == 9) {
        // faz a requisição para o back para coletar os grupos
        var resp = await store.dispatch("buscaCep", cep_busca.replace("-", ""));
        // caso o status da resposta seja 200 (deu certo)
        if (resp.status == 200) {
          // atribui a resposta na variavel grupos
          if (resp.data.dados.cmun != undefined) {
            this.empresa.logradouro = resp.data.dados.logradouro;
            this.empresa.uf_id = resp.data.dados.estado_id;
            await this.buscaCidades();
            this.empresa.cidade_id = resp.data.dados.cidade_id;
            this.empresa.bairro = resp.data.dados.bairro;
          } else {
            this.empresa.logradouro = "";
            this.empresa.cidade_id = "";
            this.empresa.uf_id = "";
            this.empresa.bairro = "";
          }
        } else {
          this.empresa.logradouro = "";
          this.empresa.cidade_id = "";
          this.empresa.uf_id = "";
          this.empresa.bairro = "";
        }
      } else {
        this.empresa.cep = "";
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "CEP incorreto!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
        this.loading = await false;
      }
    },
    async buscaEstados() {
      var resp = await store.dispatch("getEstado");
      this.estados = resp;
    },
    async buscaFormasCalculos() {
      var resp = await store.dispatch("getFormasCalculos");
      this.formas_calculos = resp;
    },
    async buscaCidades() {
      if (this.empresa.uf_id != null) {
        var resp = await store.dispatch("getCidade", this.empresa.uf_id);
        this.cidades = resp;
      }
    },
    async adicionarFrete(empresa_id) {
      let temp_frete = {
        fretes: [
          {
            empresa_id: empresa_id,
            de_km: this.novoFrete.fretes[0].de_km,
            ate_km: this.novoFrete.fretes[0].ate_km,
            estado: this.novoFrete.fretes[0].estado_id,
            cidade: this.novoFrete.fretes[0].cidade_id,
            bairro: this.novoFrete.fretes[0].bairro,
            valor_frete: this.novoFrete.fretes[0].valor_frete,
          },
        ],
      };
      if (this.$refs.form_novo_frete.validate()) {
        this.loading = await true;
        var resp = await store.dispatch("adicionaFrete", temp_frete);
        if (resp.sucesso) {
          this.$swal({
            title: "Atenção",
            icon: "success",
            text: "Frete cadastrado com sucesso!",
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: false,
          });
          this.loading = await false;
          document.location.reload(true);
        } else {
          this.$swal({
            title: "Atenção",
            icon: "warning",
            text: "Não foi possível inserir o novo frete! Verifique os dados.",
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
          this.loading = await false;
        }
      } else {
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "Preencha todos os campos obrigatórios!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      }
    },
    async removerFrete(empresa_id) {
      let dados = {
        empresa_id: empresa_id,
        usuario_id: this.dados_usuario.id,
      };

      this.loading = true;

      var resp = await store.dispatch("RemoverFrete", dados);

      if (resp.status == 200) {
        this.$swal({
          title: "Atenção",
          icon: "sucess",
          text: "Frete removido com sucesso!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });

        this.loading = await false;
        document.location.reload(true);
      } else {
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "Não foi possível remover o frete!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      }
      // retira o loading do componente
      this.loading = false;
    },
    async editarFrete(empresa_id) {
      let dados = {
        empresa_id: empresa_id,
        usuario_id: this.dados_usuario.id,
      };

      this.loading = true;

      var resp = await store.dispatch("getEndereco", dados);

      if (resp.status == 200) {
        this.novoFrete.fretes[0].empresa_id =
          (await resp.data.dados.usuario.fretes[0].empresa_id) || "";
        this.novoFrete.fretes[0].de_km =
          (await resp.data.dados.usuario.fretes[0].de_km) || "";
        this.novoFrete.fretes[0].ate_km =
          (await resp.data.dados.usuario.fretes[0].ate_km) || "";
        this.novoFrete.fretes[0].bairro =
          (await resp.data.dados.usuario.fretes[0].bairro) || "";
        this.novoFrete.fretes[0].valor_frete =
          (await resp.data.dados.usuario.fretes[0].valor_frete) || "";
        await this.buscaCidades();
        this.modal_editar_frete = true;
      } else {
        this.$swal({
          title: "Atenção",
          icon: "warning",
          text: "Não é possível alterar este frete!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      }
      // retira o loading do componente
      this.loading = false;
    },
    async salvarFreteEditado() {
      let dados = {
        codigo_endereco: this.novoEndereco.enderecos[0].codigo_endereco,
        usuario_id: this.dados_usuario.id,
        enderecos: {
          titulo_endereco: this.novoEndereco.enderecos[0].titulo_endereco,
          cep: this.novoEndereco.enderecos[0].cep,
          logradouro: this.novoEndereco.enderecos[0].logradouro,
          numero: this.novoEndereco.enderecos[0].numero,
          cidade_id:
            this.novoEndereco.enderecos[0].cidade_id == ""
              ? null
              : this.novoEndereco.enderecos[0].cidade_id,
          bairro: this.novoEndereco.enderecos[0].bairro,
          complemento: this.novoEndereco.enderecos[0].complemento,
          ponto_referencia: this.novoEndereco.enderecos[0].ponto_referencia,
        },
      };

      this.loading = true;

      var resp = await store.dispatch("salvaEnderecoEditado", dados);

      if (resp.status == 200) {
        this.$swal({
          title: "Atenção",
          icon: "sucess",
          text: "Endereço editado com sucesso!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
        });
        document.location.reload(true);
      } else {
        this.$swal({
          title: "Atenção",
          icon: "error",
          text: "Não é possível alterar este endereço!",
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      }
      // retira o loading do componente
      this.loading = false;
    },
    async limparCamposFrete() {
      this.novoFrete.fretes[0].valor_frete = "";
      this.novoFrete.fretes[0].de_km = "";
      this.novoFrete.fretes[0].ate_km = "";
      this.novoFrete.fretes[0].bairro = "";
    },
    // função para salvar os dados da empresa no financeiro
    async enviarFinanceiro(){
				let resp = {}
        // caso os campos do formulário estejam válidos
        if(this.$refs.form_empresa_servico.validate()){
            // coloca o componente como loading
            this.loading = true
            // coleta os dados da empresa
            let dados = {
                // coleta o id da empresa
                empresa_id: this.empresa_financeiro.id,
                // variável para receber o id do financeiro da empresa
                codigo: '',
                // coleta o nome da empresa
                nome: this.empresa_financeiro.nome,
                // coleta o cnpj da empresa
                cnpj: this.empresa_financeiro.cnpj.replace(/\D/g, ""),
                // coleta os recebedores da empresa
                recebedores: this.empresa_financeiro.recebedores
            }
            // caso a empresa já possua dados financeiros cadastrados
            if(this.empresa_financeiro.servico_id != ''){
                // atribui o id da empresa no financeiro
                dados.codigo = this.empresa_financeiro.servico_id
                // armazena os dados para realizar a atualização
                let date_update = {
                    dados_empresa: dados,
                    id: this.empresa_financeiro.id,
                }
                // rota para a atualização dos dados da empresa
                resp = await store.dispatch('putEmpresaFinanceiro', date_update)
            }else{
                // rota para a criação dos dados da empresa
                resp = await store.dispatch('postEmpresaFinanceiro', dados)
            }
            // caso algo tenha dado errado
            if(resp.data.sucesso == false){
                // exibe a modal informando que algo deu errado
                this.$swal({
                    title: 'Atenção',
                    icon: 'warning',
                    text: 'Algo deu errado!',
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: true,
                })
            // caso tenha dado tudo certo
            }else{
                // exibe a modal informando que deu certo
                this.$swal({
                  icon: 'success',
                  text: 'Empresa ' + (this.empresa_financeiro.servico_id != '' ? 'editada' : 'cadastrada') +  ' com sucesso!',
                  showCancelButton: false,
                  showCloseButton: false,
                  showConfirmButton: true,
                })
                // fecha a modal
                this.closeDadosFinanceiros()
            }
            // retira o loading do componente
            this.loading = false
        }else{
            // exibe a modal pedindo que os campos sejam preenchidos corretamente
            this.$swal({
                title: 'Atenção',
                icon: 'warning',
                text: 'Preencha todos os campos obrigatórios!',
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
            })
        }
    },
    // função para buscar os dados do financeiro
    async editarFinanceiro(empresa_id){
        // coloca o componente como loading
        this.loading = true
        // faz um dispatch para uma action no vuex store para pegar os dados
        var resp = await store.dispatch("getDadosFinanceiros", empresa_id)
        // caso o status da resposta seja 200 (deu certo)
        if(resp.status == 200){
            // caso a empresa já esteja cadastrada
            if(resp.data.sucesso == true){
                // altera o status da variável
                this.empresa_financeiro.servico_id = resp.data.dados.codigo
                // atribui o nome à variável local
                this.empresa_financeiro.nome = resp.data.dados.nome  
                // atribui o CNPJ à variável local
                this.empresa_financeiro.cnpj = resp.data.dados.cnpj
                // atrbui os recebedores à variável local
                this.empresa_financeiro.recebedores = resp.data.dados.recebedores
            }else if(resp.data.sucesso == false){
                // atribuimos os dados da finer manualmente
                this.empresa_financeiro.recebedores = [{
                    email_recebedor: "",
                    telefone_recebedor: "",
                    documento_recebedor: "65621365000189",
                    banco_id: 104,
                    banco_nome: "Banco Itaú BBA S.A",
                    transferencia_intervalo_recebedor: "Mensalmente",
                    transferencia_dia_recebedor: 1,
                    nome_conta_recebedor: "Finer",
                    tipo_conta_recebedor: "Conta Corrente",
                    conta_recebedor: "537623",
                    conta_dv_recebedor: "1",
                    agencia_recebedor: "3762",
                    agencia_dv_recebedor: "",
                    porcentagem_recebimento: 7
                }]
            }
            // atribui o id da empresa
            this.empresa_financeiro.id = empresa_id
            // abre a modal
            this.modal_financeiro = true
            // retira o loading do componente
            this.loading = false
        // caso algo tenha dado errado
        }else{
            // retira o loading do componente
            this.loading = false
            // exibe a modal informando o erro
            this.$swal({
                title: 'Atenção',
                icon: 'warning',
                text: 'Não é possível buscar os dados financeiros da empresa!',
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
            })
        }
    },
    // função para cadastrar um novo recebedor
    async salvarRecebedor(){
        // limpamos a variável
        this.soma = 0
        // atribuimos a porcentagem do recebedor que esta sendo editado/cadastrado
        this.soma = parseInt(this.recebedor_empresa.porcentagem_recebimento)
        // for para percorrer os dados ja existentes
        for(let i = 0; i < this.empresa_financeiro.recebedores.length; i++){
            // caso a empresa não seja a finer e nem o recebedor que esta sendo editado/cadastrado
            if(this.empresa_financeiro.recebedores[i].documento_recebedor.replace(/\D/g, "") != '65621365000189' && this.empresa_financeiro.recebedores[i].documento_recebedor.replace(/\D/g, "") != this.recebedor_empresa.documento_recebedor.replace(/\D/g, "")){
                // somamos as porcentagens
                this.soma = this.soma + parseInt(this.empresa_financeiro.recebedores[i].porcentagem_recebimento)
            }
        }
        // caso a soma seja inválida
        if(this.soma > 93){
            // exibe a modal informando o erro
            this.$swal({
                  title: 'Atenção',
                  icon: 'warning',
                  text: 'A soma das porcentagens recebida por cada recebedor não pode ser maior que 100!',
                  showCancelButton: false,
                  showCloseButton: false,
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
            })
        }else{
            // caso os campos do formulário esteja válidos
            if(this.$refs.form_empresa_recebedor.validate()){
                // rodamos o for nos bancos disponiveis
                for(let i = 0; i < this.bancos.length; i++){
                    // caso seja o mesmo banco que o selecionado pelo usuário
                    if(this.bancos[i].banco_id == this.recebedor_empresa.banco_id){
                        // atribuimos o nome do banco para mostrar na listagem
                        this.recebedor_empresa.banco_nome = this.bancos[i].nome
                    }
                }
                // caso esteja sendo editado um recebedor
                if(this.edited_index > -1){
                    // atribui os novos dados
                    Object.assign(this.empresa_financeiro.recebedores[this.edited_index], this.recebedor_empresa)
                // caso esteja sendo cadastrado um recebedor
                }else{
                    // atribui o novo recebedor
                    this.empresa_financeiro.recebedores.push(this.recebedor_empresa)
                }
                // chama a função para fechar a modal
                this.closeDadosRecebedor(this.empresa_financeiro.recebedores)
            }else{
                // exibe a modal pedindo que os campos sejam preenchidos corretamente
                this.$swal({
                    title: 'Atenção',
                    icon: 'warning',
                    text: 'Preencha todos os campos obrigatórios!',
                    showCancelButton: false,
                    showCloseButton: false,
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                })
            }
        }
    },
    // função para abrir a modal de cadastrar recebedor
    async cadastrarRecebedor(){
        // chama a função para puxar os bancos
        await this.getBancos()
        // abre a modal
        this.modal_recebedores = true
    },
    // função para editar os dados de um recebedor
    async editarRecebedor(recebedor){
        // atribui a posição do recebedor no vetor
        this.edited_index = this.empresa_financeiro.recebedores.indexOf(recebedor)
        // atribui os dados do recebedor
        this.recebedor_empresa = Object.assign({}, recebedor)
        // chama a função para abrir a modal
        this.cadastrarRecebedor()
    },
    // função para remover um recebedor
    async removerRecebedor(recebedor){
        // atribui a posição do recebedor
        this.edited_index = this.empresa_financeiro.recebedores.indexOf(recebedor)
        // atribui os dados do recebedor
        this.recebedor_empresa = Object.assign({}, recebedor)
        // exibe a modal 
        this.$swal({
            title: 'Você tem certeza?',
            text: "A exclusão não poderá ser revertida!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
          }).then((result) => {
            // caso o usuário confirme a remoção
            if (result.isConfirmed){
              // removemos o item do vetor
              this.empresa_financeiro.recebedores.splice(this.edited_index, 1)
              // ferramenta do vue para atualização imediata
              this.$nextTick(() => {
                  // atribui os dados vazios para os dados preenchidos
                  this.recebedor_empresa = Object.assign({}, this.recebedor_default)
                  // volta o marcados de posições para sua posição inicial
                  this.edited_index = -1
              })
              // exibe a mensagem
              this.$swal(
                'Deletado!',
                'Recebedor foi deletado.',
                'success'
              )
            }
        })
    },
    // função para fechar a modal de cadastro/edição dos dados financeiros da empresa
    async closeDadosFinanceiros(){
        // fecha a modal 
        this.modal_financeiro = false
        // limpa os dados locais
        this.empresa_financeiro = {
            id: '',
            servico_id: '',
            nome: '',
            cnpj: '',
            recebedores: [],
        }
    },
    // função para fechar a modal de cadastro/edição de um recebedor
    async closeDadosRecebedor(){
        // fecha a modal
        this.modal_recebedores = false
        // ferramenta do vue para atualização imediata
        this.$nextTick(() => {
            // atribui os dados vazios para os dados preenchidos
            this.recebedor_empresa = Object.assign({}, this.recebedor_default)
            // volta o marcados de posições para sua posição inicial
            this.edited_index = -1
        })
    },
    // função para buscar os bancos disponíveis
    async getBancos(){
        // coloca o componente como loading
        this.loading = true
        // faz um dispatch para uma action no vuex store para pegar os bancos
        var resp = await store.dispatch('getBancos', this.empresa_financeiro.id)
        // caso o status da resposta seja 200 (deu certo)
        if(resp.status == 200){
            // atribui os dados à variável local
            this.bancos = resp.data.dados.banco
            // retira o loading do componente
            this.loading = false
        // caso algo tenha dado errado
        }else{
            // retira o loading do componente
            this.loading = false
            // exibe a modal informando o erro
            this.$swal({
                title: 'Atenção',
                icon: 'warning',
                text: 'Não foi possível buscar os bancos disponíveis!',
                showCancelButton: false,
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonText: 'OK',
            })
        }
    }
  },
  mounted() {
    // função de início do componente
    this.init();
  },
};
</script>

<style lang="scss" scoped>
#empresas {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
  padding: 24px;
  font-family: "Poppins";
  .primary-button {
    background-color: #fe8204;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 15px 20px;
    svg {
      margin-right: 5px;
    }
  }
  .tabela_ {
    .status {
      img {
        width: 20px;
        margin-left: 10px;
      }
    }
  }
  .tabela-empresas {
    border-radius: 10px;
    width: 100%;
    .novo-empresa {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 0;
      button {
        i,
        svg {
          margin-right: 10px;
        }
        text-transform: uppercase;
      }
    }
    h2 {
      font-size: 25px;
      color: #404040;
      border-bottom: 4px solid #fe8204;
      width: max-content;
    }
    table {
      font-weight: 500;
    }
  }
}
.v-dialog {
  font-family: "Poppins";
  .v-card {
    background-color: #f9f9f9;
  }
  .v-card__title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-transform: uppercase;
    font-family: "Poppins" !important;
    span {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-weight: 600;
      color: #404040;
      font-family: "Poppins" !important;
      img,
      svg {
        margin-right: 15px;
        path {
          fill: #404040;
        }
      }
    }
    button {
      color: #fff;
    }
    .exit-button {
      color: #404040;
      font-weight: 600;
      font-size: 15px;
      box-shadow: none;
      background-color: transparent;
      svg {
        margin-right: 0;
      }
    }
    p {
      font-size: 14px;
      width: 100%;
      color: #969696;
      margin-bottom: 0;
    }
  }
  .div-input {
    padding: 5px;
  }
  .primary-button {
    background-color: #fe8204 !important;
    color: #fff !important;
    font-weight: 500;
    font-size: 18px;
    padding: 15px 20px;
  }
  .secondary-button {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    &:hover {
      background-color: transparent;
    }
  }
  .wrapper-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    button {
      font-size: 18px;
    }
  }
}
#modal-nova-empresa,
#modal-frete,
#modal-adicionar-frete,
#modal-editar-frete {
  padding: 15px;
  .titulo-modal,
  .v-card__title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 25px;
    color: #404040;
    text-transform: uppercase;
    .exit-button {
      color: #404040;
      font-weight: 600;
      font-size: 15px;
      box-shadow: none;
      background-color: transparent;
      svg {
        margin-right: 0;
      }
    }
  }
  .v-card__actions {
    display: flex;
    justify-content: center !important;
    .wrapper-btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }
  }
}
</style>